import type { HandleClientError } from "@sveltejs/kit";
import * as Sentry from "@sentry/sveltekit";
import { PUBLIC_APP_ENV } from "$env/static/public";

Sentry.init({
  environment: PUBLIC_APP_ENV,
  enabled: PUBLIC_APP_ENV != "development",
  dsn: "https://d72641853bde303edce17cbcdd9329b5@o4506462773706752.ingest.sentry.io/4506462774886400",
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 1,
  integrations: [Sentry.replayIntegration()],
});

const logError: HandleClientError = ({ error, event }) => {
  console.error("An error occurred on the CLIENT side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(logError);
